import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        font-family: Inconsolata;
    }
    html {
        width: 100%;
        height: 100%;
    }
    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: white;
        color: black;
        font-size: 1.125rem;
        font-family: Inconsolata;
        line-height: 1.6rem;
        font-weight: 400;
    }
    h1 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.375rem;
        color: black;
        @media (min-width: 1200px) {
            font-size: 2.625rem;
            line-height: 4rem;
        }
    }
    h2 {
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.5rem;
        color: black;
        @media (min-width: 1200px) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
    h3 {
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.8rem;
        color: black;
    }
    h4 {
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.6rem;
        color: black;
    }
    a {
        display: inline-block;
        // text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: 0;
        }
    }
    p {
        margin: 0;
    }
    ul {
        margin: 0;
    }
    li {
        margin: 5px 0;
    }
`;

export default GlobalStyle;
