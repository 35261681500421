import React from "react";
import styled from "styled-components";

import {
	AiFillGithub,
	AiOutlineTwitter,
	AiFillLinkedin,
	AiFillMail,
} from "react-icons/ai";

const iconSize = 30;

const StyledFooter = styled.footer`
	width: 75%;
	min-width: 75%;
	max-width: 75%;
	height: 85px;
	margin: 10px auto 0px auto;
	padding: 0 40px;
	background: white;
`;

const StyledContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-top: 1px solid black;
`;

const StyledAnchor = styled.a`
	height: ${iconSize}px;
	width: ${iconSize}px;
	margin: 0px 10px;
`;

const Footer = () => (
	<StyledFooter>
		<StyledContainer>
			<StyledAnchor
				href="https://github.com/FellowshipOfThePing"
				target="_blank"
				rel="noreferrer"
			>
				<AiFillGithub size={iconSize} />
			</StyledAnchor>

			<StyledAnchor
				href="https://twitter.com/generictechbro"
				target="_blank"
				rel="noreferrer"
			>
				<AiOutlineTwitter size={iconSize} />
			</StyledAnchor>

			<StyledAnchor
				href="https://www.linkedin.com/in/jeremy-binder/"
				target="_blank"
				rel="noreferrer"
			>
				<AiFillLinkedin size={iconSize} />
			</StyledAnchor>

			<StyledAnchor href="mailto: jeremysbinder@gmail.com">
				<AiFillMail size={iconSize} />
			</StyledAnchor>
		</StyledContainer>
	</StyledFooter>
);

export default Footer;
