import React from "react";
import styled from "styled-components";

import GlobalStyle from "./globalStyle";
import Header from "./header";
import Footer from "./footer";
import Seo from "./seo";

const StyledLayout = styled.div`
	width: 100%;
	min-width: 630px;
	min-height: 100vh;
	margin: 0 auto;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;

	#main-content {
		width: 75%;
		max-width: 75%;
		margin: 0 auto;
		padding: 0 2.5rem;
	}
`;

const Layout = ({ children }) => {
	return (
		<StyledLayout>
			<GlobalStyle />
			<Seo />
			<Header />
			<main id="main-content">{children}</main>
			<Footer />
		</StyledLayout>
	);
};

export default Layout;
