import React from "react";
import { Link } from "gatsby";

const linkStyles = {
	fontSize: "1.2rem",
	fontWeight: "500",
	color: "black",
	paddingRight: "20px",
	textDecoration: "none",
};

const activeStyles = {
	textDecoration: "underline",
};

const NavLink = ({ children, to }) => {
	return (
		<Link to={to} style={linkStyles} activeStyle={activeStyles}>
			{children}
		</Link>
	);
};

export default NavLink;
