import React from "react";
import styled from "styled-components";

import NavLink from "./navLink";

const StyledHeader = styled.header`
	width: 75%;
	min-width: 75%;
	max-width: 75%;
	height: 150px;
	margin: 20px auto 30px auto;
	padding: 0 40px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
`;

const StyledLogo = styled.div`
	font-size: 2rem;
	font-weight: 900;
	color: black;
	font-family: Inconsolata;
`;

const LinkSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 0px;
	border-bottom: 1px solid black;
`;

const StyledLink = styled.div`
	font-size: 1.2rem;
	font-weight: 500;
	font-family: Inconsolata;
	color: black;
	margin-right: 20px;
	&:hover {
		text-decoration: underline;
	}
`;

const Header = () => {
	return (
		<StyledHeader>
			<StyledLogo>Jeremy Binder</StyledLogo>
			<LinkSection>
				<NavLink to="/">
					<StyledLink>Home</StyledLink>
				</NavLink>
				<NavLink to="/portfolio">
					<StyledLink>Portfolio</StyledLink>
				</NavLink>
				{/* <NavLink to="/resume">
          <StyledLink>Resume</StyledLink>
        </NavLink> */}
			</LinkSection>
		</StyledHeader>
	);
};

export default Header;
